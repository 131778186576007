import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import { graphql } from 'gatsby';

import { setPageHeading } from '../state/app/app-actions';

class PostsPage extends PureComponent {
  constructor() {
    super();
  }

  componentDidMount() {
    this.props.updatePageHeading('Some posts about stuff');
  }

  render() {
    return (
      <div>
        <h1>Posts about things will live here one day...</h1>
        <p>Coming soon.</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    pageHeading
  } = state.app;

  return {
    pageHeading
  };
}

const mapDispatchToProps = (dispatch) => {
  const updatePageHeading = (heading) => dispatch(setPageHeading(heading));

  return {
    updatePageHeading
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostsPage);


export const query = graphql`
  query PostsPage {
    contentfulPage(title: {eq: "Posts"}) {
      heading
      subHeading
      mainContent {
        mainContent
      }
    }
  }
`;
